import { Component, Input } from '@angular/core'
import { UntilDestroy } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
  selector: 'mg-add-shop-sections',
  templateUrl: './add-shop-sections.component.html',
  styleUrls: ['./add-shop-sections.component.scss']
})
export class AddShopSectionsComponent {
  @Input() activeSection = 1
}
