<div class="bg-contrast h-full w-full relative flex flex-col overflow-auto">
  <!-- Close Btn -->
  <div *ngIf="data.singleSelector" class="absolute cursor-pointer top-4.5 right-4.5">
    <svg-icon color="#6B7980" key="close" fontSize="17px" (click)="dialogRef.close()"></svg-icon>
  </div>

  <mg-compare-with
    [selections]="data.selections"
    [singleSelector]="data.singleSelector"
    [hiddenSections]="data.hiddenSections"
    pillSize="m"
    (selectionsChanged)="dialogRef.close($event)"
    (canceld)="dialogRef.close()"
    [allNationals]="data.allNationals"
    [allRegions]="data.allRegions"
    [allShops]="data.allShops"
    [allStates]="data.allStates"
    [organizationAverageIsSelected]="data.organizationAverageIsSelected"
  ></mg-compare-with>
</div>
