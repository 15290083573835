import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { UntilDestroy } from '@ngneat/until-destroy'
import { Sort } from '@angular/material/sort'
import { ITableDefenition } from '../../table-actions.interface'
import { ISelectItem } from '@mg-platform/core/core-ui'
import { cloneDeep } from 'lodash'

@UntilDestroy()
@Component({
  selector: 'mg-table-action-sort',
  templateUrl: './table-action-sort.component.html',
  styleUrls: ['./table-action-sort.component.scss']
})
export class TableActionSortComponent implements OnInit {
  @Input() sorts: Sort[]
  @Input() allColumns: ISelectItem[] = []
  innerSorts: Sort[] = []
  @Input() tableDefenition: ITableDefenition[]

  @Output() sortsChanged = new EventEmitter<Sort[]>()

  remainingColumns: ISelectItem[] = []
  sortDirections: ISelectItem[] = [
    { label: 'Ascending', value: 'asc' },
    { label: 'Descending', value: 'desc' }
  ]

  ngOnInit(): void {
    this.innerSorts = cloneDeep(this.sorts)
    this.remainingColumns = [...this.allColumns]
  }

  onMenuClose(): void {
    setTimeout(() => {
      this.innerSorts = cloneDeep(this.sorts)
      this.setRemainingColumns()
    }, 200)
  }

  addSort(key: string) {
    this.innerSorts?.push({ active: key, direction: 'asc' })
    this.setRemainingColumns()
  }

  deleteSort(index: number) {
    this.innerSorts.splice(index, 1)
    this.setRemainingColumns()
  }

  setDefault() {
    this.innerSorts = []
    this.emitSortChange()
    this.setRemainingColumns()
  }

  emitSortChange() {
    this.sortsChanged.emit([...this.innerSorts])
  }

  setRemainingColumns() {
    const activeColumns = this.innerSorts.map((s) => s.active)
    this.remainingColumns = this.allColumns.filter((el) => !activeColumns.includes(el.value))
  }
}
