<div #parentContainer>
  <div #contentSlot (click)="!disable ? itemsMenuTrigger.openMenu() : undefined">
    <ng-content></ng-content>
  </div>
  <div
    *ngIf="!contentSlot.children.length"
    class="w-max flex items-center px-1 py-0.5 border border-ui-separator rounded-0.5"
    [ngClass]="{ 'cursor-pointer': !disable, '!w-full': fullWidth }"
    (click)="!disable ? itemsMenuTrigger.openMenu() : undefined"
  >
    <div class="flex-1 text-txt text-lg leading-xl">{{ selectedItemLabel ?? placeHolder }}</div>
    <div *ngIf="!disable" class="ml-1 flex text-txt-secondary">
      <svg-icon key="arrow-down-simple" fontSize="9px"></svg-icon>
    </div>
  </div>
  <div [matMenuTriggerFor]="itemsMenu" #itemsMenuTrigger="matMenuTrigger"></div>
</div>

<mat-menu #itemsMenu="matMenu" [hasBackdrop]="true" class="mg-filter-menu">
  <div
    class="p-0.5 flex flex-col overflow-auto w-full min-w-max"
    [style.width.px]="fullWidth ? parentContainer.clientWidth : 'auto'"
    (click)="$event.stopPropagation()"
  >
    <div
      *ngFor="let item of items"
      class="p-0.5 cursor-pointer hover:bg-ui-hover text-txt text-lg leading-xl"
      [ngClass]="{ '!bg-ui-hover-1': item.value === selectedItem }"
      (click)="selectedItemChanged.emit(item.value); itemsMenuTrigger.closeMenu()"
    >
      {{ item.label }}
    </div>
  </div>
</mat-menu>
