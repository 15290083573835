<div
  class="mb-3 md:mb-5.5 flex flex-col md:flex-row items-center print:flex-row flex-wrap {{ extraClass }}"
>
  <div class="mt-2 md:mt-4 flex-1 flex flex-col pr-2 w-full">
    <div class="text-2xl leading-2.5xl font-medium text-txt capitalize">{{ title }}</div>
    <div #subTitleSlot>
      <ng-content select="[slot=subTitle]"></ng-content>
    </div>
    <div *ngIf="!subTitleSlot.children.length && subTitle" class="mt-1 text-xl leading-2xl text-txt">
      {{ subTitle }}
    </div>
  </div>

  <ng-content></ng-content>
</div>
