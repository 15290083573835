<div class="bg-contrast h-full w-full relative p-3.5 overflow-auto">
  <!-- Close Btn -->
  <div class="absolute cursor-pointer top-4.5 right-4.5">
    <svg-icon color="#6B7980" key="close" fontSize="16px" (click)="dialogRef.close()"></svg-icon>
  </div>

  <div class="mt-5.5 group">
    <ng-container *ngFor="let item of data.routes">
      <div
        *ngIf="!item.hidden"
        class="menu-item"
        [routerLink]="item.path"
        routerLinkActive="!bg-ui-selected !text-white"
        [routerLinkActiveOptions]="{ exact: !!item.exact }"
        (click)="closeDialog()"
      >
        <div class="menu-icon">
          <svg-icon [key]="item.icon" fontSize="16px"></svg-icon>
        </div>
        <div class="menu-item-label">
          <div class="pl-3">{{ item.label }}</div>
        </div>
      </div>
    </ng-container>
    <div *ngIf="data.showLogout" class="menu-item cursor-pointer" (click)="logout()">
      <div class="menu-icon">
        <svg-icon key="logout" fontSize="16px"></svg-icon>
      </div>
      <div class="menu-item-label">
        <div class="pl-3">Log Out</div>
      </div>
    </div>
  </div>
</div>
