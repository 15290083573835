<div class="flex-1 flex flex-col overflow-hidden py-3.5">
  <div class="flex-1 overflow-y-auto px-3.5 beautyScroll">
    <div class="flex">
      <div class="flex-1">
        <div class="text-1.25xl font-medium leading-2xl">Compare Shop</div>
        <div class="mt-0.5 text-lg leading-xl text-txt">
          {{
            singleSelector
              ? 'Select single element to compare.'
              : 'Select single or multiple elements to compare.'
          }}
        </div>
      </div>
      <div
        *ngIf="!singleSelector"
        class="pl-4 text-error text-lg leading-xl font-medium cursor-pointer"
        (click)="resetSelection()"
      >
        Clear all
      </div>
    </div>

    <form class="mt-4 md:mt-3 -mb-1" [formGroup]="form">
      <mg-input
        [autoFocus]="true"
        [normalInputId]="searchInputId"
        controlName="searchText"
        type="text"
        placeholder="Search"
        size="small"
        icon="search"
        [clearable]="true"
        clearIconSize="14px"
      ></mg-input>
    </form>

    <!-- My Organization -->
    <ng-container
      *ngIf="
        !hiddenSections?.includes('shops') &&
        organizationHasMoreThanOneShop &&
        (visibleShops.length > 0 || showOrganizationAverage)
      "
    >
      <div class="section-title flex items-center">
        <mg-dynamic-icons extraClass="text-txt mr-2" [marketType]="marketTypes.shop"></mg-dynamic-icons>
        <span>My Organization</span>
      </div>
      <div class="section-pills-container">
        <mg-pill
          *ngIf="!singleSelector"
          [label]="
            numberOfSelectedShops === allShops.length + (organizationAverageIsSelected ? 0 : 1)
              ? 'Deselect all'
              : 'Select all'
          "
          [size]="pillSize"
          [selected]="
            numberOfSelectedShops === allShops.length + (organizationAverageIsSelected ? 0 : 1)
          "
          (click)="toggleAllShops()"
        >
        </mg-pill>
        <mg-pill
          *ngIf="showOrganizationAverage && !organizationAverageIsSelected"
          label="My Organization"
          [size]="pillSize"
          [selected]="selections.organizationAverage"
          (click)="setOrganizationAverage()"
        >
        </mg-pill>
        <mg-pill
          *ngFor="let item of showAllShops ? visibleShops : visibleShops.slice(undefined, 5)"
          [label]="item.label"
          [size]="pillSize"
          [selected]="selections.shops.includes(item.value)"
          (click)="toggleSelection(item.value, 'shops')"
        >
        </mg-pill>
        <mg-pill
          *ngIf="visibleShops.length > 6"
          [size]="pillSize"
          [label]="showAllShops ? '-' : '+'"
          (click)="showAllShops = !showAllShops"
        ></mg-pill>
      </div>
    </ng-container>

    <!-- Country Swich Tab -->
    <ng-container *ngIf="showCountrySwitcher">
      <div class="flex mt-4">
        <div
          class="tab-item"
          [ngClass]="{ '!bg-ui-fields !border-b-0': selectedCountry === countryCodes.USA }"
          (click)="selectedCountry = countryCodes.USA; setVisibleStatesAndRegions()"
        >
          <mg-country-flag [countryCode]="countryCodes.USA"></mg-country-flag>
          <div class="tab-title">United States</div>
        </div>
        <div
          class="tab-item"
          [ngClass]="{ '!bg-ui-fields !border-b-0': selectedCountry === countryCodes.CA }"
          (click)="selectedCountry = countryCodes.CA; setVisibleStatesAndRegions()"
        >
          <mg-country-flag [countryCode]="countryCodes.CA"></mg-country-flag>
          <div class="tab-title">Canada</div>
        </div>
      </div>
    </ng-container>

    <div
      [ngClass]="{
        'p-3 bg-ui-fields border border-ui-separator rounded-b-1 md:rounded-tr-1': showCountrySwitcher
      }"
    >
      <!-- State -->
      <ng-container *ngIf="!hiddenSections?.includes('states') && visibleStates.length > 0">
        <div
          class="section-title flex items-center"
          [ngClass]="{ '!pt-0': userCountry === countryCodes.ALL }"
        >
          <mg-dynamic-icons
            extraClass="text-txt mr-2"
            [marketType]="marketTypes.state"
          ></mg-dynamic-icons>
          <span>{{ selectedCountry === countryCodes.USA ? 'State' : 'Provences' }}</span>
        </div>
        <div class="section-pills-container">
          <mg-pill
            *ngFor="
              let item of showAllStates
                ? visibleStates
                : visibleStates.slice(undefined, selectedCountry === countryCodes.USA ? 9 : 6)
            "
            [label]="item.label"
            [size]="pillSize"
            [selected]="selections.states.includes(item.value)"
            (click)="toggleSelection(item.value, 'states')"
          >
          </mg-pill>
          <mg-pill
            *ngIf="visibleStates.length > 12"
            [size]="pillSize"
            [label]="showAllStates ? '-' : '+'"
            (click)="showAllStates = !showAllStates"
          ></mg-pill>
        </div>
      </ng-container>

      <!-- Regional -->
      <ng-container *ngIf="!hiddenSections?.includes('regions') && visibleRegions.length > 0">
        <div
          class="section-title flex items-center"
          [ngClass]="{ '!mt-0': userCountry === countryCodes.ALL }"
        >
          <mg-dynamic-icons
            extraClass="text-txt mr-2"
            [marketType]="marketTypes.regional"
          ></mg-dynamic-icons>
          <span>{{ selectedCountry === countryCodes.USA ? 'Regions' : 'Territories' }}</span>
        </div>
        <div class="section-pills-container">
          <mg-pill
            *ngFor="let item of showAllRegions ? visibleRegions : visibleRegions.slice(undefined, 7)"
            [label]="item.label"
            [size]="pillSize"
            [selected]="selections.regions.includes(item.value)"
            (click)="toggleSelection(item.value, 'regions')"
          >
          </mg-pill>
          <mg-pill
            *ngIf="visibleRegions.length > 6"
            [size]="pillSize"
            [label]="showAllRegions ? '-' : '+'"
            (click)="showAllRegions = !showAllRegions"
          ></mg-pill>
        </div>
      </ng-container>
    </div>

    <!-- National -->
    <ng-container *ngIf="!hiddenSections?.includes('nationals') && visibleNationals.length > 0">
      <div class="section-title flex items-center">
        <mg-dynamic-icons
          extraClass="text-txt mr-2"
          [marketType]="marketTypes.national"
        ></mg-dynamic-icons>
        <span>National</span>
      </div>
      <div class="section-pills-container">
        <mg-pill
          *ngFor="let item of visibleNationals"
          [label]="item.label"
          [size]="pillSize"
          [selected]="selections.nationals.includes(item.value)"
          (click)="toggleSelection(item.value, 'nationals')"
        >
        </mg-pill>
      </div>
    </ng-container>
  </div>

  <div *ngIf="!singleSelector" class="flex flex-col lg:flex-row-reverse lg:items-center mt-3 px-3.5">
    <mg-button
      class="lg:ml-3 mb-1 lg:mb-0 flex-1"
      size="medium"
      (click)="saveSelections()"
      [disabled]="numberOfSelection < 1"
    >
      <ng-container *ngIf="numberOfSelection < 1; else hasSelectionTemplate">
        No selection
      </ng-container>
      <ng-template #hasSelectionTemplate>
        Apply {{ numberOfSelection }} selection{{ numberOfSelection > 1 ? 's' : '' }}
      </ng-template>
    </mg-button>
    <mg-button class="flex-1" size="medium" variant="secondary" (click)="canceld.emit()">
      Cancel
    </mg-button>
  </div>
</div>
