import { Component, EventEmitter, Input, Output } from '@angular/core'
import { UntilDestroy } from '@ngneat/until-destroy'
import { IShop } from '@mg-platform/shops/shops-data-access'
import { PillSize } from '@mg-platform/core/core-ui'

@UntilDestroy()
@Component({
  selector: 'mg-included-shops',
  templateUrl: './included-shops.component.html',
  styleUrls: ['./included-shops.component.scss']
})
export class IncludedShopsComponent {
  @Input() shopsList: IShop[] = []
  @Input() pillSize: PillSize = 'm'

  @Output() canceld = new EventEmitter()
}
