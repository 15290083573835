<div class="p-4 h-full flex flex-col overflow-y-auto overflow-x-hidden {{ extraClasses }}">
  <div class="pr-5 md:pr-0 text-1.25xl md:text-xl leading-2xl font-medium text-txt">Theme settings</div>
  <div class="pr-5 md:pr-0 mt-0.5 mb-5 text-lg leading-xl text-txt">
    Select the type of colors and modes you prefer.
  </div>

  <form [formGroup]="form" class="flex-1 flex flex-col">
    <div class="mb-3 text-txt text-xl md:text-lg leading-xl">Modes</div>

    <div
      *ngFor="let item of darkModeTypes"
      (click)="form.get('type')?.setValue(item.value)"
      class="mb-3 py-2 md:py-1 pl-3 md:pl-2 pr-2 md:pr-1 text-txt flex items-center bg-contrast border border-ui-separator rounded-2 cursor-pointer"
      [ngClass]="{ 'bg-primary border-primary text-white': form.get('type')?.value === item.value }"
    >
      <div class="flex mr-2">
        <svg-icon [key]="item.value + '-mode'" fontSize="14px"></svg-icon>
      </div>
      <div class="text-xl md:text-lg leading-2xl md:leading-xl">{{ item.label }}</div>
    </div>

    <div class="my-3 text-txt text-xl md:text-lg leading-xl">Color theme</div>

    <div
      *ngFor="let item of colorPalettes"
      class="mb-3 p-3 md:p-2 rounded-2 flex items-center bg-contrast border border-ui-separator cursor-pointer"
      [ngClass]="{ 'bg-primary border-primary': item.value === this.form.get('palette')?.value }"
      (click)="this.form.get('palette')?.setValue(item.value)"
    >
      <div class="flex items-center">
        <div
          *ngFor="let color of item.extraInfo"
          class="w-3 h-3 rounded-full mr-1 last:mr-0"
          [style.background]="color"
        ></div>
      </div>
      <div
        class="flex-1 text-right text-xl md:text-base leading-xl md:leading-base text-txt-secondary"
        [ngClass]="{ 'text-white': item.value === this.form.get('palette')?.value }"
      >
        {{ item.label }}
      </div>
    </div>
  </form>
</div>
