<div class="side-menu-container group">
  <img
    class="mb-4 ml-1 cursor-pointer self-start"
    src="assets/images/icons/logo.svg"
    routerLink="/dashboard"
    width="32"
    alt="Logo"
  />

  <div class="w-full">
    <ng-container *ngFor="let item of mainMenuRoutes">
      <div
        *ngIf="!item.hidden"
        class="menu-item"
        [routerLink]="item.path"
        routerLinkActive="menu-item-selected"
        [routerLinkActiveOptions]="{ exact: !!item.exact }"
      >
        <div class="menu-icon">
          <svg-icon [key]="item.icon" fontSize="16px"></svg-icon>
        </div>
        <div class="menu-item-label">
          <div class="pl-3">{{ item.label }}</div>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="w-full">
    <div class="menu-item">
      <div class="menu-icon">
        <svg-icon key="user" fontSize="16px"></svg-icon>
      </div>
      <div class="menu-item-label" *ngIf="currentUser">
        <div class="pl-3">{{ currentUser.firstName }} {{ currentUser.lastName }}</div>
      </div>
    </div>
  </div>

  <div class="w-0 flex overflow-hidden group-hover:overflow-visible group-hover:w-full">
    <div class="h-full border-r border-ui-separator mt-0.5 pl-4 mr-3"></div>
    <div class="flex-1">
      <ng-container *ngFor="let item of profileMenuRoutes">
        <div
          *ngIf="!item.hidden"
          class="menu-item !h-5 !p-1"
          [routerLink]="item.path"
          routerLinkActive="menu-item-selected"
          [routerLinkActiveOptions]="{ exact: !!item.exact }"
        >
          <div class="menu-item-label !text-lg">
            {{ item.label }}
          </div>
        </div>
      </ng-container>
      <div class="menu-item !h-5 !p-1 cursor-pointer" (click)="logout()">
        <div class="menu-item-label !text-lg">Log Out</div>
      </div>
    </div>
  </div>
</div>
