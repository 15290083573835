import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'
import { ISelectItem, PillSize } from '@mg-platform/core/core-ui'
import { ORGANIZATION_AVERAGE } from '@mg-platform/core/core-util'
import { IShop, ShopsState } from '@mg-platform/shops/shops-data-access'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Store } from '@ngxs/store'
import { groupBy } from 'lodash'
import { Observable } from 'rxjs'

@UntilDestroy()
@Component({
  selector: 'mg-shop-selector',
  templateUrl: './shop-selector.component.html',
  styleUrls: ['./shop-selector.component.scss']
})
export class ShopSelectorComponent implements OnInit {
  @Input() selectedShopId: string
  @Input() pillSize: PillSize = 'm'
  @Input() showOrganizationAverage: boolean
  @Output() shopIdChanged = new EventEmitter<string>()

  $allShops: Observable<IShop[]> = inject(Store).select(ShopsState.allShops)
  allShops: ISelectItem[] = []
  selectedShops: { [key: string]: ISelectItem[] }

  form: UntypedFormGroup
  searchInputId = 'shop_selector_search_input'
  organizationId = ORGANIZATION_AVERAGE.id

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      searchText: [null]
    })

    this.form
      .get('searchText')
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe((value: string) => {
        const trimValue = value?.toLowerCase().trim() ?? ''
        if (trimValue === '') {
          this.groupShopsbyRegion(this.allShops)
        } else {
          this.groupShopsbyRegion(
            this.allShops.filter((el) => el.label.toLowerCase().includes(trimValue))
          )
        }
      })

    this.$allShops.pipe(untilDestroyed(this)).subscribe((res) => {
      if (!res || res.length < 1) {
        return
      }
      this.allShops = res.map((el) => ({
        label: el.nickname ?? el.name,
        value: el.id,
        extraInfo: el.regionName
      }))
      if (this.showOrganizationAverage) {
        this.allShops = [
          {
            label: ORGANIZATION_AVERAGE.name,
            value: ORGANIZATION_AVERAGE.id,
            extraInfo: ''
          },
          ...this.allShops
        ]
      }
      this.groupShopsbyRegion(this.allShops)
    })
  }

  groupShopsbyRegion(items: ISelectItem[]) {
    this.selectedShops = groupBy([...items], (x) => x.extraInfo)
  }

  public setFocusOnSearchInput() {
    setTimeout(() => {
      document.getElementById(this.searchInputId)?.focus()
    })
  }
}
