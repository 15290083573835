<div class="flex items-center">
  @if (pill) {
  <div
    class="px-3 py-1 text-xl leading-2xl text-txt bg-ui-pills rounded-full cursor-pointer"
    [ngClass]="{
      'bg-ui-selected !text-white': item ? formControl.value?.includes(item.value) : formControl.value
    }"
    (click)="toggleCheckbox(item?.value)"
  >
    {{ item?.label ?? label }}
  </div>
  } @else {
  <input
    type="checkbox"
    [checked]="item ? formControl.value?.includes(item.value) : formControl.value"
    [disabled]="disabled"
    (click)="toggleCheckbox(item?.value)"
    class="appearance-none h-3.5 w-3.5 rounded-0.5 border border-gray-500 focus:outline-none cursor-pointer"
    [ngClass]="{ hidden: item ? formControl.value?.includes(item.value) : formControl.value }"
  />

  <div
    *ngIf="item ? formControl.value?.includes(item.value) : formControl.value"
    class="h-3.5 w-3.5 rounded-0.5 flex items-center justify-center text-white bg-primary cursor-pointer"
    (click)="toggleCheckbox(item?.value)"
  >
    <svg-icon class="check-icon pointer-events-none" key="tick" fontSize="9px"></svg-icon>
  </div>

  <div
    class="flex-1 ml-2 text-txt-secondary text-lg leading-2xl cursor-pointer {{ labelClass }}"
    [ngClass]="{ '!text-txt-disabled': disabled }"
    (click)="toggleCheckbox(item?.value)"
  >
    {{ item?.label ?? label }}
  </div>
  }
</div>
