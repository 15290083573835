<div class="flex flex-col lg:flex-row">
  <div class="flex-1 min-h-screen flex flex-col">
    <div class="flex-1 flex flex-col">
      <div class="lg:hidden print:hidden">
        <mg-main-header
          [forAdmin]="true"
          [mainMenuRoutes]="mainMenuRoutes"
          [profileMenuRoutes]="profileMenuRoutes"
        ></mg-main-header>
      </div>

      <div class="content-container">
        <div class="side-menu">
          <mg-side-menu
            [forAdminPanel]="true"
            [mainMenuRoutes]="mainMenuRoutes"
            [profileMenuRoutes]="profileMenuRoutes"
          ></mg-side-menu>
        </div>
        <div id="main-content" class="main-content beautyScroll" style="scrollbar-gutter: stable">
          <div class="outlet-wrapper">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
