<div
  class="w-full flex flex-col-reverse md:flex-row justify-between items-center md:items-end bg-ui-card rounded-3.5 md:rounded-4"
  *ngIf="info"
>
  <div class="flex-1 md:p-4 p-3.5 flex flex-col text-text text-xl leading-2xl">
    <div #titleSlot>
      <ng-content select="[slot=title]"></ng-content>
    </div>
    <span *ngIf="!titleSlot.children.length">{{ info.title }}</span>
    <span *ngIf="info.subTitle">
      {{ info.subTitle }}
    </span>
    <div *ngIf="variant === 'shopNotVerified'" class="mt-3.5 w-full md:w-max">
      <mg-button class="w-full" size="medium" type="button" routerLink="/shops">
        Add more shops
      </mg-button>
    </div>
  </div>
  <div class="flex justify-end pt-4 pb-0.5 md:py-2 px-3.5 md:pr-5.5">
    <mg-dynamic-images class="h-[176px] w-fit" [key]="info.imageKey"></mg-dynamic-images>
  </div>
</div>
