<div class="flex">
  <mg-table-action-sort
    [sorts]="tableAction.sorts ?? []"
    [allColumns]="allColumns"
    [tableDefenition]="tableDefenition"
    (sortsChanged)="onSortChanged($event)"
  ></mg-table-action-sort>

  <div class="self-stretch pl-1 mr-1 border-r border-ui-separator"></div>

  <div class="flex-1 flex items-center flex-wrap gap-1">
    <div *ngFor="let item of tableAction.filters ?? []; let index = index">
      <mg-table-filter
        #filterItems
        [filter]="item"
        [tableDefenition]="tableDefenition"
        (filterRemoved)="removeFilter(item.key)"
        (filterChanged)="onFilterChanged($event, index)"
      ></mg-table-filter>
    </div>

    <mg-dropdown-menu
      *ngIf="remainingColumns.length > 0"
      [items]="remainingColumns"
      (selectedItemChanged)="addFilter($event)"
    >
      <div class="flex items-center text-lg leading-xl text-txt-secondary cursor-pointer">
        <svg-icon key="add" fontSize="11px"></svg-icon>
        <span class="pl-2">Add filter</span>
      </div>
    </mg-dropdown-menu>
  </div>

  <div
    class="px-1 py-0.5 text-lg leading-xl text-txt-secondary cursor-pointer"
    (click)="removeAllFilters()"
  >
    Clear filters
  </div>
</div>
