<ng-container *ngIf="organizationAverageId === shopId && shopIds && shopIds.length > 0">
  <div
    class="text-xl leading-2xl text-primary underline cursor-pointer"
    (click)="window.innerWidth < mobileMaxWidth ? openDialog() : menuTrigger.openMenu()"
  >
    View included shops.
  </div>
  <div
    [matMenuTriggerFor]="window.innerWidth >= mobileMaxWidth ? includedShopsMenu : null"
    #menuTrigger="matMenuTrigger"
  ></div>

  <mat-menu
    #includedShopsMenu="matMenu"
    [hasBackdrop]="true"
    backdropClass="mg-menu-backdrop"
    class="mg-filter-menu"
  >
    <div class="w-[390px] p-3.5 md:p-4" (click)="$event.stopPropagation()">
      <mg-included-shops
        [shopsList]="shopsList"
        pillSize="s"
        (canceld)="menuTrigger.closeMenu()"
      ></mg-included-shops>
    </div>
  </mat-menu>
</ng-container>
