import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { UntilDestroy } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
  selector: 'mg-shop-selector-dialog',
  templateUrl: './shop-selector-dialog.component.html',
  styleUrls: ['./shop-selector-dialog.component.scss']
})
export class ShopSelectorDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ShopSelectorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { selectedShopId: string; showOrganizationAverage: boolean }
  ) {}
}
