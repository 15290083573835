<div class="mb-3.5 text-1.25xl font-medium leading-2xl">Select Week</div>

<div
  *ngFor="let item of availableWeeks"
  class="px-4 py-2 mb-1 last:mb-0 rounded-2 text-xl leading-2xl text-txt hover:bg-ui-hover cursor-pointer"
  [ngClass]="{
    'bg-ui-hover-1': item.value === selectedWeekNumber
  }"
  (click)="selectedWeekChanged.emit(item.value)"
>
  {{ item.label }}
</div>
