import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { UntilDestroy } from '@ngneat/until-destroy'
import {
  AggregatorType,
  ITableDefenition,
  ITableFilter,
  ITableFilterRule,
  TableColumnType,
  TableOperator
} from '../../table-actions.interface'
import { MatMenuTrigger } from '@angular/material/menu'
import { ISelectItem } from '@mg-platform/core/core-ui'
import {
  AbstractControl,
  FormBuilder,
  UntypedFormArray,
  UntypedFormGroup,
  Validators
} from '@angular/forms'
import { NumberOperators, StringOperators } from '../../table-actions.constant'
import { IRangeDateInfo } from '../../../../interfaces/range-date.interface'
import { PeriodType, TodayString } from '@mg-platform/core/core-data-access'

@UntilDestroy()
@Component({
  selector: 'mg-table-filter',
  templateUrl: './table-filter.component.html',
  styleUrls: ['./table-filter.component.scss']
})
export class TableFilterComponent implements OnInit {
  @ViewChild(MatMenuTrigger) menu: MatMenuTrigger

  @Input() filter: ITableFilter
  @Input() tableDefenition: ITableDefenition[]

  @Output() filterChanged = new EventEmitter<ITableFilter>()
  @Output() filterRemoved = new EventEmitter()

  columnDefenition: ITableDefenition
  tableColumnTypes = TableColumnType

  form: UntypedFormGroup
  aggregatorControl: AbstractControl | null
  itemsControl: UntypedFormArray
  operators: ISelectItem[]
  defaultOperator: TableOperator
  aggregatorItems: ISelectItem[] = [
    {
      label: 'Match any',
      value: AggregatorType.Or
    },
    {
      label: 'Match all',
      value: AggregatorType.And
    }
  ]
  selectedDateInfo: IRangeDateInfo
  todayString = TodayString

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.columnDefenition = this.tableDefenition.find(
      (el) => el.key === this.filter.key
    ) as ITableDefenition

    switch (this.columnDefenition.columnType) {
      case TableColumnType.text:
        this.operators = StringOperators
        this.defaultOperator = TableOperator.Contains
        break

      case TableColumnType.number:
        this.operators = NumberOperators
        this.defaultOperator = TableOperator.Equals
        break

      case TableColumnType.checkbox:
      case TableColumnType.radio:
        this.defaultOperator = TableOperator.Equals
        break

      case TableColumnType.date:
        this.defaultOperator = TableOperator.Period
        break
    }

    this.form = this.fb.group({
      aggregator: [this.filter.aggregator],
      items: this.fb.array([])
    })

    this.aggregatorControl = this.form.get('aggregator')
    this.itemsControl = this.form.get('items') as UntypedFormArray
    this.updateForm()
  }

  updateForm() {
    this.itemsControl.clear()
    this.aggregatorControl?.setValue(this.filter.aggregator)
    if (!this.filter?.items || this.filter.items.length < 1) {
      this.addNewRule()
    } else {
      for (const item of this.filter.items) {
        this.addNewRule(item)
      }
    }
  }

  onMenuClose(): void {
    setTimeout(() => {
      this.updateForm()
    }, 200)
  }

  public openMenu(): void {
    this.menu.openMenu()
  }

  addNewRule(item?: ITableFilterRule) {
    if (item?.value && this.columnDefenition.columnType === TableColumnType.date) {
      this.selectedDateInfo = {
        periodType: PeriodType.Custom,
        rangeDate: item.value
      }
    }

    this.itemsControl.push(
      this.fb.group({
        operator: [item?.operator ?? this.defaultOperator],
        value: [item?.value, Validators.required]
      })
    )
  }

  clearFilters() {
    this.itemsControl.clear()
    this.addNewRule()
    this.filterChanged.emit({ ...this.filter, items: [], columnType: this.columnDefenition.columnType })
  }

  removeRule(index: number) {
    this.itemsControl.removeAt(index)
    if (this.itemsControl?.value?.length === 0) {
      this.addNewRule()
    }
  }

  onDateChange(formControl: AbstractControl, event: IRangeDateInfo) {
    this.selectedDateInfo = event
    if (this.selectedDateInfo.rangeDate?.from && this.selectedDateInfo.rangeDate?.to) {
      formControl.setValue({
        from: this.selectedDateInfo.rangeDate.from,
        to: this.selectedDateInfo.rangeDate.to
      })
    }
  }

  submit() {
    this.form.markAllAsTouched()
    if (this.form.valid) {
      const newFilters: ITableFilter = this.form.value
      this.filterChanged.emit({
        ...this.filter,
        ...newFilters,
        columnType: this.columnDefenition.columnType
      })
      this.menu.closeMenu()
    }
  }
}
