<div
  class="mt-2 mr-1 text-center rounded-full cursor-pointer {{ extraClass }}"
  [ngClass]="{
    'm-size': size === 'm',
    's-size': size === 's',
    'xs-size': size === 'xs',
    'bg-ui-selected text-white': selected,
    'bg-ui-pills text-txt': !selected
  }"
>
  {{ label }}
  <ng-content></ng-content>
</div>
