<div class="modal-container">
  <div class="title">Select shop focus</div>
  <div class="sub-title mt-0.5">Select the product mix that best describes your shop.</div>

  <form class="flex-1 flex flex-col mt-[28px] w-full" [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="flex-1 flex flex-col">
      <div class="mt-3 first:mt-0" *ngFor="let item of shopFocusItems">
        <mg-radio [item]="item" controlName="focusType"></mg-radio>
      </div>
    </div>

    <div class="flex flex-col lg:flex-row-reverse lg:items-center mt-4">
      <mg-button
        class="lg:ml-3 mb-1 lg:mb-0 flex-1"
        type="submit"
        size="medium"
        [loading]="isProcessing"
        [disabled]="form.invalid"
      >
        Save
      </mg-button>
      <mg-button class="flex-1" size="medium" variant="secondary" (click)="dialogRef.close()">
        Cancel
      </mg-button>
    </div>
  </form>
</div>
