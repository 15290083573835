import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'
import {
  FiltersState,
  ICompareWithSelection,
  ICompareWithType,
  SetFilters
} from '@mg-platform/core/core-data-access'
import { ISelectItem } from '@mg-platform/core/core-ui'
import { MarketType, WeeklyReportsState } from '@mg-platform/reports/reports-data-access'
import {
  GetAllUserShops,
  GetOneShopFromAllShops,
  IShop,
  SetOrganizationAverageAsShop,
  ShopFocusType,
  ShopsState
} from '@mg-platform/shops/shops-data-access'
import { IUser, UserAccountState } from '@mg-platform/users/users-data-access'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Store } from '@ngxs/store'
import { debounceTime, finalize, Observable } from 'rxjs'
import { IFilterFields } from './filter-form.interface'
import { MatDialog } from '@angular/material/dialog'
import { ShopSelectorDialogComponent } from '../shop-selector-dialog/shop-selector-dialog.component'
import {
  FullScreenModalConfig,
  MobileMaxWidth,
  NormalModalConfig,
  ORGANIZATION_AVERAGE,
  environment
} from '@mg-platform/core/core-util'
import { WeekSelectorDialogComponent } from '../week-selector-dialog/week-selector-dialog.component'
import { ShopFocusDialogComponent } from '../shop-focus-dialog/shop-focus-dialog.component'

@UntilDestroy()
@Component({
  selector: 'mg-filter-form',
  templateUrl: './filter-form.component.html',
  styleUrls: ['./filter-form.component.scss']
})
export class FilterFormComponent implements OnInit {
  @Input() debounceTime = 200
  @Input() autoSize = false
  @Input() filterFields: IFilterFields = {
    shop: true,
    marketType: true,
    compareWith: false,
    weekNumber: false
  }
  @Input() showOrganizationAverage = !environment.production || environment.name === 'app'
  @Input() hiddenCompareWithSections: ICompareWithType[] = []

  @Input() marketTypes: ISelectItem[] = [
    {
      label: 'National',
      value: 'national'
    },
    {
      label: 'Regional',
      value: 'regional'
    },
    {
      label: 'State',
      value: 'state'
    },
    {
      label: 'Local (Coming Soon)',
      value: 'local',
      disabled: true
    }
  ]

  compareWith$: Observable<ICompareWithSelection> = inject(Store).select(FiltersState.compareWith)

  @Output() formValueChanged = new EventEmitter()
  @Output() isLoadingChanged = new EventEmitter<boolean>()

  $allShops: Observable<IShop[]> = inject(Store).select(ShopsState.allShops)
  allShopsInfo: IShop[] = []
  allShops: ISelectItem[] = []

  currentUser: IUser

  availableWeeks: ISelectItem[] = []
  form: UntypedFormGroup
  loadingShops = false
  window = window
  mobileMaxWidth = MobileMaxWidth
  selectedShop: string
  selectedWeekLabel?: string
  organizationHasMoreThanOneShop = false

  constructor(
    private store: Store,
    private fb: UntypedFormBuilder,
    private dialogService: MatDialog
  ) {}

  ngOnInit() {
    this.currentUser = this.store.selectSnapshot(UserAccountState.currentUser)
    this.organizationHasMoreThanOneShop = !!(this.currentUser?.organizationShopNumber > 1)
    if (this.organizationHasMoreThanOneShop) {
      this.marketTypes.splice(3, 0, {
        label: 'My Organization',
        value: 'organization'
      })
    }

    this.$allShops.pipe(untilDestroyed(this)).subscribe((res) => {
      if (!res || res.length < 1) {
        return
      }
      this.allShopsInfo = res
      this.allShops = res.map((el) => ({
        label: el.nickname ?? el.name,
        value: el.id
      }))
    })

    const filterMarketType = this.store.selectSnapshot<MarketType | string>(FiltersState.marketType)
    const compareWith = this.store.selectSnapshot(FiltersState.compareWith)
    this.form = this.fb.group({
      shopId: [null],
      marketType: [
        filterMarketType === null || filterMarketType === undefined || filterMarketType === 'undefined'
          ? MarketType.national
          : filterMarketType
      ],
      weekNumber: [null],
      compareWith: [compareWith]
    })

    this.form.valueChanges
      .pipe(untilDestroyed(this), debounceTime(this.debounceTime))
      .subscribe((values) => {
        setTimeout(() => {
          this.formValueChanged.emit(values)
        })
      })

    this.compareWith$.pipe(untilDestroyed(this)).subscribe((res) => {
      if (res) {
        this.form.get('compareWith')?.setValue(res)
      }
    })

    this.form
      .get('shopId')
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe((value) => {
        this.selectedShop =
          value === ORGANIZATION_AVERAGE.id
            ? ORGANIZATION_AVERAGE.name
            : (this.allShops.find((el) => el.value === value)?.label ?? '')
      })

    this.form
      ?.get('weekNumber')
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe((res) => {
        this.selectedWeekLabel = this.availableWeeks.find((el) => el.value === res)?.label
      })

    if (this.filterFields.shop) {
      if (this.allShops && this.allShops.length > 0) {
        this.setCurrentVisibleShop()
      } else {
        this.loadingShops = true
        this.emitLoadingStatus()
        this.store
          .dispatch(new GetAllUserShops())
          .pipe(
            untilDestroyed(this),
            finalize(() => {
              this.loadingShops = false
              this.emitLoadingStatus()
            })
          )
          .subscribe()
      }
    }

    this.availableWeeks = (this.store.selectSnapshot(WeeklyReportsState.availableWeeks) ?? []).map(
      (el) => ({
        label: el.period,
        value: el.weekNumber
      })
    )

    const weekNumberControl = this.form?.get('weekNumber')
    if (!weekNumberControl?.value && this.availableWeeks.length > 0) {
      const savedWeekNumber = this.store.selectSnapshot<number>(FiltersState.weekNumber)
      if (savedWeekNumber && this.availableWeeks.find((el) => el.value === savedWeekNumber)) {
        weekNumberControl?.setValue(savedWeekNumber)
      } else {
        weekNumberControl?.setValue(this.availableWeeks[0].value)
      }
    }
  }

  getShopInfo() {
    const shopId = this.form?.get('shopId')?.value
    if (!shopId) {
      return
    }

    this.store.dispatch(
      new SetFilters({
        shopId
      })
    )

    if (shopId === ORGANIZATION_AVERAGE.id) {
      this.store.dispatch(new SetOrganizationAverageAsShop())
      return
    }

    this.store.dispatch(new GetOneShopFromAllShops(shopId))

    // TODO: Shop focus on shop change
    // this.openShopsFocusDialog(shopId)
  }

  setCompareWith(compareWith: ICompareWithSelection) {
    this.store.dispatch(
      new SetFilters({
        compareWith: {
          organizationAverage: this.hiddenCompareWithSections.includes('shops')
            ? false
            : compareWith.organizationAverage,
          shops: this.hiddenCompareWithSections.includes('shops') ? [] : compareWith.shops,
          nationals: this.hiddenCompareWithSections.includes('nationals') ? [] : compareWith.nationals,
          regions: this.hiddenCompareWithSections.includes('regions') ? [] : compareWith.regions,
          states: this.hiddenCompareWithSections.includes('states') ? [] : compareWith.states
        }
      })
    )
  }

  setMarketType() {
    const marketType = this.form.get('marketType')?.value
    if (!marketType) {
      return
    }
    this.store.dispatch(
      new SetFilters({
        marketType
      })
    )
  }

  setWeekNumber(weekNumber: number) {
    this.form.get('weekNumber')?.setValue(weekNumber)
    if (!weekNumber) {
      return
    }
    this.store.dispatch(
      new SetFilters({
        weekNumber
      })
    )
  }

  setCurrentVisibleShop() {
    const shopIdControl = this.form?.get('shopId')
    if (!shopIdControl?.value && this.allShops.length > 0) {
      let shopId = this.store.selectSnapshot<string>(FiltersState.shopId) ?? this.allShops[0].value
      if (
        (shopId === ORGANIZATION_AVERAGE.id && !this.showOrganizationAverage) ||
        (shopId !== ORGANIZATION_AVERAGE.id && !this.allShops.find((el) => el.value === shopId))
      ) {
        shopId = this.allShops[0].value
      }
      shopIdControl?.setValue(shopId)
      this.getShopInfo()
    }
  }

  emitLoadingStatus() {
    this.isLoadingChanged.emit(this.loadingShops)
  }

  openShopSelectorDialog() {
    this.dialogService
      .open(ShopSelectorDialogComponent, {
        ...FullScreenModalConfig,
        data: {
          selectedShopId: this.form.get('shopId')?.value,
          showOrganizationAverage: this.showOrganizationAverage
        }
      })
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        if (value) {
          this.form.get('shopId')?.setValue(value)
          this.getShopInfo()
        }
      })
  }

  openWeekSelectorDialog() {
    this.dialogService
      .open(WeekSelectorDialogComponent, {
        ...FullScreenModalConfig,
        data: {
          availableWeeks: this.availableWeeks,
          selectedWeekNumber: this.form.get('weekNumber')?.value
        }
      })
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        if (value) {
          this.setWeekNumber(value)
        }
      })
  }

  openShopsFocusDialog(shopId: string) {
    const shopFocus = this.allShopsInfo.find((el) => el.id === shopId)?.focus
    if (this.currentUser?.isOrganizationOwner && (!shopFocus || shopFocus === ShopFocusType.NotSet)) {
      if (this.dialogService.openDialogs.length == 0) {
        const modalConfig =
          window.innerWidth < MobileMaxWidth ? { ...FullScreenModalConfig } : { ...NormalModalConfig }
        this.dialogService.open(ShopFocusDialogComponent, {
          ...modalConfig,
          backdropClass: 'mg-menu-backdrop'
        })
      }
    }
  }
}
