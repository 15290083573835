<div>
  <div
    class="px-2 py-0.5 flex items-center text-lg leading-xl text-txt-secondary rounded-full border border-txt-secondary cursor-pointer"
    [ngClass]="{ '!border-primary !text-primary': filter.items && filter.items.length > 0 }"
    (click)="filterMenuTrigger.openMenu()"
  >
    <svg-icon key="filters" fontSize="12px"></svg-icon>
    <span class="mx-1">{{ columnDefenition.label }}</span>
    <svg-icon
      (click)="$event.stopPropagation(); filterRemoved.emit()"
      key="close"
      fontSize="8px"
    ></svg-icon>
  </div>
  <div [matMenuTriggerFor]="filterMenu" #filterMenuTrigger="matMenuTrigger"></div>
</div>

<mat-menu
  #filterMenu="matMenu"
  [hasBackdrop]="true"
  backdropClass="mg-menu-backdrop"
  class="mg-filter-menu"
  (closed)="onMenuClose()"
>
  <div
    class="px-2 py-1 flex flex-col w-[320px]"
    (click)="$event.stopPropagation()"
    (keydown.Tab)="$event.stopPropagation()"
  >
    <div class="text-lg leading-xl font-medium text-txt">{{ columnDefenition.label }}</div>

    <form class="mt-2" [formGroup]="form" (ngSubmit)="submit()">
      <div class="mb-1" *ngIf="operators">
        <mg-dropdown-menu
          [fullWidth]="true"
          [items]="aggregatorItems"
          [selectedItem]="aggregatorControl?.value"
          (selectedItemChanged)="aggregatorControl?.setValue($event)"
        >
        </mg-dropdown-menu>
      </div>

      <div formArrayName="items" *ngFor="let itemForm of $any(itemsControl['controls']); let i = index">
        <ng-container [formGroup]="itemForm">
          @switch (columnDefenition.columnType) {
            @case (tableColumnTypes.checkbox) {
              <div
                *ngFor="let item of columnDefenition.items ?? []"
                class="flex items-center mb-1 cursor-pointer"
              >
                <mg-checkbox
                  [item]="item"
                  labelClass="!text-lg !text-txt"
                  controlName="value"
                ></mg-checkbox>
              </div>
            }

            @case (tableColumnTypes.radio) {
              <div
                *ngFor="let item of columnDefenition.items ?? []"
                class="flex items-center mb-1 cursor-pointer"
              >
                <mg-radio
                  [item]="item"
                  labelClass="!text-lg !text-txt"
                  [circleSize]="18"
                  controlName="value"
                ></mg-radio>
              </div>
            }

            @case (tableColumnTypes.date) {
              <mg-range-date-picker
                [standalone]="true"
                [value]="selectedDateInfo"
                [maxDate]="todayString"
                [hideAllPeriods]="true"
                (valueChanged)="onDateChange(itemForm.get('value'), $event)"
              ></mg-range-date-picker>
            }

            @default {
              <div class="flex">
                <mg-dropdown-menu
                  class="mr-1"
                  [items]="operators"
                  [selectedItem]="itemForm.get('operator')?.value"
                  (selectedItemChanged)="itemForm.get('operator')?.setValue($event)"
                >
                </mg-dropdown-menu>
                <mg-input
                  controlName="value"
                  [type]="$any(columnDefenition.columnType)"
                  size="extra-small"
                  placeholder="Value"
                ></mg-input>

                <div
                  (click)="removeRule(i)"
                  class="flex ml-2 p-1 pt-2 text-txt-secondary cursor-pointer"
                >
                  <svg-icon key="close" fontSize="10px"></svg-icon>
                </div>
              </div>
            }
          }
        </ng-container>
      </div>

      <div
        *ngIf="operators"
        (click)="addNewRule()"
        class="mt-1 flex items-center text-lg leading-xl text-txt-secondary cursor-pointer"
      >
        <svg-icon key="add" fontSize="11px"></svg-icon>
        <span class="pl-2">Add filter rule</span>
      </div>
    </form>

    <div class="mt-3 flex items-center justify-between">
      <div class="text-lg leading-xl text-primary cursor-pointer" (click)="submit()">Filter</div>
      <div
        class="text-lg leading-xl text-txt-secondary cursor-pointer"
        (click)="filterMenuTrigger.closeMenu(); filterRemoved.emit()"
      >
        Delete filter
      </div>
    </div>
  </div>
</mat-menu>
