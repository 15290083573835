<div class="flex items-start">
  <div class="flex-1">
    <div class="text-1.25xl font-medium leading-2xl text-txt">Included Shops</div>
    <div class="mt-0.5 text-lg leading-2xl text-txt">
      Shops from My Organization that have enough data for the comparison.
    </div>
  </div>

  <div class="flex ml-4 cursor-pointer">
    <svg-icon color="#6B7980" key="close" fontSize="14px" (click)="canceld.emit()"></svg-icon>
  </div>
</div>

<div class="mt-2 flex flex-wrap">
  <mg-pill
    *ngFor="let item of shopsList"
    [label]="item.nickname ?? item.name"
    [size]="pillSize"
    extraClass="!cursor-auto"
  >
  </mg-pill>
</div>
