import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core'
import { UntilDestroy } from '@ngneat/until-destroy'
import {
  AggregatorType,
  ITableAction,
  ITableActionNotifier,
  ITableDefenition,
  ITableFilter
} from './table-actions.interface'
import { ISelectItem } from '@mg-platform/core/core-ui'
import { TableFilterComponent } from './components/table-filter/table-filter.component'
import { Sort } from '@angular/material/sort'

@UntilDestroy()
@Component({
  selector: 'mg-table-actions',
  templateUrl: './table-actions.component.html',
  styleUrls: ['./table-actions.component.scss']
})
export class TableActionsComponent implements OnInit {
  @ViewChildren('filterItems') filterItems: QueryList<TableFilterComponent>

  @Input() tableDefenition: ITableDefenition[] = []
  @Input() tableAction: ITableAction = { page: 1 }

  @Output() tableActionChanged = new EventEmitter<ITableActionNotifier>()

  allColumns: ISelectItem[] = []
  remainingColumns: ISelectItem[] = []

  ngOnInit(): void {
    this.allColumns = this.tableDefenition.map((el) => ({
      label: el.label,
      value: el.key
    }))
    this.remainingColumns = [...this.allColumns]
  }

  addFilter(key: string) {
    const tableFilter: ITableFilter = {
      key,
      items: [],
      aggregator: AggregatorType.Or
    }
    this.tableAction.filters = this.tableAction.filters
      ? [...this.tableAction.filters, tableFilter]
      : [tableFilter]

    setTimeout(() => {
      this.filterItems?.last?.openMenu()
    })
    this.fireTableActionChanged(false)
    this.setRemainingColumns()
  }

  removeFilter(key: string) {
    const removeFilter = this.tableAction.filters?.find((el) => el.key === key)
    this.tableAction.filters = this.tableAction.filters?.filter((el) => el.key !== key)
    this.fireTableActionChanged(!!(removeFilter?.items && removeFilter.items.length > 0))
    this.setRemainingColumns()
  }

  removeAllFilters() {
    const removeFilters = [...(this.tableAction.filters ?? [])]
    this.tableAction.filters = []
    this.fireTableActionChanged(!!removeFilters.find((el) => el.items && el.items.length > 0))
    this.setRemainingColumns()
  }

  onSortChanged(sorts: Sort[]) {
    this.tableAction.sorts = sorts
    this.fireTableActionChanged(true)
  }

  onFilterChanged(filter: ITableFilter, index: number) {
    this.tableAction.filters
      ? (this.tableAction.filters[index] = filter)
      : (this.tableAction.filters = [filter])
    this.fireTableActionChanged(true)
  }

  setRemainingColumns() {
    this.remainingColumns = this.allColumns.filter(
      (el) => !this.tableAction.filters?.find((x) => x.key === el.value)
    )
  }

  fireTableActionChanged(isChanged: boolean) {
    this.tableActionChanged.emit({ ...this.tableAction, isChanged })
  }
}
