<div class="text-1.25xl font-medium leading-2xl">Select shop</div>

<form class="mt-4" [formGroup]="form">
  <mg-input
    [autoFocus]="true"
    [normalInputId]="searchInputId"
    *ngIf="allShops.length > 6"
    controlName="searchText"
    type="text"
    placeholder="Search shop"
    size="small"
    icon="search"
    [clearable]="true"
    clearIconSize="14px"
  ></mg-input>
</form>

<div>
  <div class="mt-4 first:mt-3" *ngFor="let group of selectedShops | keyvalue">
    <div
      *ngIf="group.key && group.key !== ''"
      class="-mb-0.5 text-lg leading-xl font-medium text-txt-secondary"
    >
      {{ group.key }}
    </div>
    <div class="flex flex-wrap">
      <mg-pill
        *ngFor="let item of group.value"
        [extraClass]="item.value === organizationId ? '!mt-0' : ''"
        [label]="item.label"
        [size]="pillSize"
        [selected]="selectedShopId === item.value"
        (click)="shopIdChanged.emit(item.value)"
      >
      </mg-pill>
    </div>
  </div>
</div>
