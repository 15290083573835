<div
  (click)="
    window.innerWidth < mobileMaxWidth ? openRangeDatePickerDialog() : menuTrigger.openMenu();
    rangePicker.setCalendarDate()
  "
>
  <div #contentSlot>
    <ng-content></ng-content>
  </div>

  <div
    *ngIf="!contentSlot.children.length"
    class="w-full md:w-[300px] p-2 rounded-3 bg-ui-bg md:hover:bg-ui-hover flex justify-between items-center cursor-pointer {{
      extraClasses
    }}"
  >
    <div class="flex-1 overflow-hidden pr-1 flex flex-col">
      <div class="text-base leading-base text-txt-secondary">
        {{ selectedPeriodType ? periodTypeToLabel[selectedPeriodType] : 'Select period' }}
      </div>
      <div class="text-xl leading-2xl truncate">{{ formattedPeriodTime }}</div>
    </div>

    <div class="flex items-center justify-center p-2 bg-primary rounded-2 text-contrast">
      <svg-icon key="calendar-light" fontSize="15px"></svg-icon>
    </div>
  </div>
</div>

<div
  [matMenuTriggerFor]="window.innerWidth >= mobileMaxWidth ? dateRangePickerMenu : null"
  #menuTrigger="matMenuTrigger"
  (menuClosed)="setSelectedDate()"
></div>

<mat-menu
  #dateRangePickerMenu="matMenu"
  [hasBackdrop]="true"
  backdropClass="mg-menu-backdrop"
  class="mg-filter-menu"
>
  <div class="flex flex-col overflow-auto w-[520px]" (click)="$event.stopPropagation()">
    <mg-range-date-picker
      #rangePicker
      [value]="selectedValue"
      [maxDate]="maxDate"
      [minDate]="minDate"
      [hideAllPeriods]="hideAllPeriods"
      [hiddenPeriods]="hiddenPeriods"
      (valueChanged)="valueChanged.emit($event); menuTrigger.closeMenu()"
      (canceld)="menuTrigger.closeMenu()"
    ></mg-range-date-picker>
  </div>
</mat-menu>
