<div class="p-3.5 md:p-0 flex-1 flex flex-col md:justify-center bg-contrast">
  <mg-add-shop-sections [activeSection]="activeSection === 'contact' ? 2 : 1"></mg-add-shop-sections>

  <ng-container [ngSwitch]="activeSection">
    <ng-container *ngSwitchCase="'place'">
      <div class="title pt-3.5">Locate your shop</div>
      <div class="sub-title pb-5">Find your shop on the map or enter the address manually.</div>

      <form [formGroup]="googlePlaceForm" class="flex-1 md:flex-none flex flex-col items-center">
        <mg-google-place-input
          class="w-full flex-1"
          (addressChanged)="shopAddressSelected($event)"
          placeholder="Enter your shop location"
          [googlePlacesCountries]="googlePlacesCountries"
          controlName="location"
        ></mg-google-place-input>

        <mg-button class="mt-5.5 w-full" type="button" size="medium" (click)="addManually()">
          Add manually
        </mg-button>
        <mg-button
          class="mt-2 w-full"
          type="button"
          size="medium"
          variant="ghost"
          (click)="canceld.emit()"
        >
          {{ cancelBtnLabel }}
        </mg-button>
      </form>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <div class="title pt-3.5">
        <ng-container *ngIf="activeSection === 'details'"> Enter shop details </ng-container>
        <ng-container *ngIf="activeSection === 'contact'"> Add contact info </ng-container>
      </div>
      <div class="sub-title pb-5">
        <ng-container *ngIf="activeSection === 'details'">
          Fill in your shop's name and address information.
        </ng-container>
        <ng-container *ngIf="activeSection === 'contact'">
          Fill in your shop's contact information.
        </ng-container>
      </div>

      <form [formGroup]="form" (ngSubmit)="onSubmit()" class="flex-1 md:flex-none flex flex-col">
        <ng-container *ngIf="activeSection === 'details'">
          <div class="flex-1 md:flex-none flex flex-col">
            <mg-input controlName="name" type="text" size="medium" label="Shop Name"></mg-input>
            <mg-input
              controlName="countryCode"
              type="select"
              label="Country"
              size="medium"
              [items]="allCountries"
            ></mg-input>
            <mg-input controlName="address" type="text" size="medium" label="Address"></mg-input>
            <mg-input controlName="city" type="text" size="medium" label="City"></mg-input>
            <mg-input
              controlName="postCode"
              type="text"
              label="Zip Code"
              size="medium"
              [errorMessages]="[
                {
                  type: 'required',
                  message: 'This is required'
                }
              ]"
            ></mg-input>
            <mg-input
              controlName="stateId"
              type="select"
              label="State"
              size="medium"
              [items]="states"
              [loading]="loadingStates"
            ></mg-input>
          </div>

          <mg-button
            class="mt-5.5 w-full"
            type="button"
            size="medium"
            icon="arrow-right-full"
            iconPosition="right"
            iconType="svgIcon"
            iconClass="flex"
            [disabled]="detailsFormIsInvalid()"
            (click)="continueToContactForm()"
          >
            Continue
          </mg-button>
          <mg-button
            class="mt-2 md:pb-2 w-full"
            type="button"
            size="medium"
            variant="ghost"
            (click)="googlePlaceForm.reset(); form.reset(); activeSection = 'place'"
          >
            Back
          </mg-button>
        </ng-container>

        <ng-container *ngIf="activeSection === 'contact'">
          <div class="flex-1 md:flex-none flex flex-col">
            <mg-input
              controlName="phoneNumber"
              type="text"
              label="Phone Number"
              size="medium"
              mask="(000) 000-0000"
              prefix="+1"
              [errorMessages]="[
                {
                  type: 'US-phone',
                  message: 'This is not is US/CA valid phone number'
                }
              ]"
            ></mg-input>
            <mg-input
              controlName="websiteUrl"
              type="text"
              label="Website"
              size="medium"
              [errorMessages]="[
                {
                  type: 'invalidUrl',
                  message: 'Please enter a valid url'
                }
              ]"
            ></mg-input>
            <mg-input controlName="nickname" type="text" size="medium" label="Shop Nickname"></mg-input>
            <mg-input
              controlName="numberOfBays"
              type="number"
              label="Bays at Shop"
              size="medium"
              [errorMessages]="[
                {
                  type: 'min',
                  message: 'Can not be less than 1'
                },
                {
                  type: 'max',
                  message: 'Can not be more than 50'
                }
              ]"
            ></mg-input>
          </div>

          <mg-button
            class="mt-5.5 w-full"
            type="submit"
            size="medium"
            icon="arrow-right-full"
            iconPosition="right"
            iconType="svgIcon"
            iconClass="flex"
            [loading]="isProcessing"
            [disabled]="form.invalid"
          >
            Continue
          </mg-button>
          <mg-button
            class="mt-2 md:pb-2 w-full"
            type="button"
            size="medium"
            variant="ghost"
            (click)="activeSection = 'details'"
          >
            Back
          </mg-button>
        </ng-container>
      </form>
    </ng-container>
  </ng-container>
</div>
