<div class="flex items-center">
  <input
    id="{{ controlName }}-{{ item.value }}-radio"
    type="radio"
    [value]="item.value"
    class="hidden"
    [formControl]="formControl"
    name="{{ controlName }}"
  />
  <label for="{{ controlName }}-{{ item.value }}-radio" class="flex group">
    <div class="mt-[3px]">
      <ng-container *ngIf="formControl?.value !== item.value">
        <div class="flex text-txt-secondary group-hover:hidden">
          <svg-icon fontSize="{{ circleSize }}px" key="circle-empty"></svg-icon>
        </div>
        <div class="hidden text-primary group-hover:flex">
          <svg-icon fontSize="{{ circleSize }}px" key="circle-borderd"></svg-icon>
        </div>
      </ng-container>
      <img
        [style.width.px]="circleSize"
        class="max-w-none"
        *ngIf="formControl?.value === item.value"
        src="assets/images/circle-fill.svg"
      />
    </div>
    <div class="ml-2 text-xl leading-2xl text-txt {{ labelClass }}">{{ item.label }}</div>
  </label>
</div>
