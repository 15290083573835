<div class="p-3.5 md:p-0 flex-1 flex flex-col md:justify-center bg-contrast">
  <mg-add-shop-sections [activeSection]="4"></mg-add-shop-sections>

  <div class="title pt-3.5">Set service prices</div>
  <div class="sub-title pb-5">Enter the standard prices for your main services.</div>

  <form
    *ngIf="!isLoadingGetThresholds; else loadingTemplate"
    [formGroup]="form"
    (ngSubmit)="onSubmit()"
    class="flex-1 md:flex-none flex flex-col"
  >
    <div class="flex-1 md:flex-none flex flex-col">
      <mg-input
        class="w-full"
        size="medium"
        controlName="laborRate"
        [useCurrencyMask]="true"
        label="Labor Rate"
        [errorMessages]="[
          {
            type: 'min',
            message: 'Should be more than $' + thresholds.minLaborRate
          },
          {
            type: 'max',
            message: 'Should be less than $' + thresholds.maxLaborRate
          }
        ]"
      ></mg-input>
      <mg-input
        class="w-full"
        size="medium"
        controlName="alingment"
        [useCurrencyMask]="true"
        label="Alignment"
        [errorMessages]="[
          {
            type: 'min',
            message: 'Should be more than $' + thresholds.minAlignment
          },
          {
            type: 'max',
            message: 'Should be less than $' + thresholds.maxAlignment
          }
        ]"
      ></mg-input>
      <mg-input
        class="w-full"
        size="medium"
        controlName="oilChange"
        [useCurrencyMask]="true"
        label="Oil Change"
        [errorMessages]="[
          {
            type: 'min',
            message: 'Should be more than $' + thresholds.minOilChange
          },
          {
            type: 'max',
            message: 'Should be less than $' + thresholds.maxOilChange
          }
        ]"
      ></mg-input>
    </div>

    <mg-button
      class="mt-5 w-full"
      type="submit"
      size="medium"
      [loading]="isProcessing"
      [disabled]="form.invalid"
    >
      Done
    </mg-button>
    <mg-button
      class="mt-2 md:pb-2 w-full"
      type="button"
      size="medium"
      variant="ghost"
      (click)="completed.emit()"
    >
      Skip
    </mg-button>
  </form>
</div>

<ng-template #loadingTemplate>
  <div class="mt-5 flex justify-center">
    <mat-progress-spinner [diameter]="80" mode="indeterminate"></mat-progress-spinner>
  </div>
</ng-template>
