<button
  [attr.type]="type"
  [disabled]="disabled || loading"
  [ngClass]="{
    'btn-primary': variant === 'primary',
    'btn-primary-invert': variant === 'primary-invert',
    'btn-accent': variant === 'accent',
    'btn-secondary': variant === 'secondary',
    'btn-error': variant === 'error',
    'btn-ghost': variant === 'ghost',
    'btn-ghost-invert': variant === 'ghost-invert',
    'btn-disabled': this.disabled,
    'size-large': size === 'large',
    'size-medium': size === 'medium',
    'size-small': size === 'small',
    'size-smaller': size === 'smaller'
  }"
  class="btn-base relative {{ extraBtnClass }}"
  [disabled]="loading"
>
  <div
    class="w-full flex justify-center items-center"
    [ngClass]="{ 'flex-row-reverse': iconPosition === 'right' }"
  >
    <div
      *ngIf="loading"
      class="absolute w-full h-full top-0 left-0 flex items-center justify-center text-white {{
        extraBtnClass
      }}"
      [ngClass]="{
        'rounded-2': size !== 'smaller',
        'rounded-1': size === 'smaller',
        'btn-primary': variant === 'primary',
        'btn-primary-invert': variant === 'primary-invert',
        'btn-accent': variant === 'accent',
        'btn-secondary !bg-gray-300': variant === 'secondary',
        'btn-error': variant === 'error',
        'btn-ghost': variant === 'ghost',
        'btn-ghost-invert': variant === 'ghost-invert',
        'btn-disabled': this.disabled
      }"
    >
      <span class="loader"></span>
    </div>
    <div *ngIf="icon">
      <div [ngSwitch]="iconType" class="{{ iconClass }}">
        <img *ngSwitchCase="'iconPath'" [src]="icon" />
        <svg-icon *ngSwitchCase="'svgIcon'" [key]="icon"></svg-icon>
        <mat-icon *ngSwitchDefault>{{ icon }}</mat-icon>
      </div>
    </div>
    <div
      [ngClass]="{
        'mr-3': icon && iconPosition === 'right',
        'ml-3': icon && iconPosition === 'left',
        'lowercase first-letter:uppercase': lowerCase
      }"
    >
      <ng-content></ng-content>
    </div>
  </div>
</button>
