<form
  [formGroup]="form"
  class="w-full flex flex-col md:flex-row print:flex-row justify-center md:justify-end print:justify-end items-center"
>
  <!-- Shop Selector -->
  <div class="w-full" [ngClass]="{ 'md:w-[300px]': !autoSize }">
    <div
      *ngIf="filterFields.shop"
      class="p-2 rounded-3 bg-ui-bg flex justify-between items-center"
      [ngClass]="{
        'pointer-events-none':
          !organizationHasMoreThanOneShop || (!showOrganizationAverage && allShops.length === 1),
        'cursor-pointer md:hover:bg-ui-hover':
          organizationHasMoreThanOneShop && (showOrganizationAverage || allShops.length > 1)
      }"
      (click)="
        window.innerWidth < mobileMaxWidth ? openShopSelectorDialog() : menuTrigger.openMenu();
        shopSelectorRef.setFocusOnSearchInput()
      "
    >
      <div class="flex flex-col">
        <div class="text-base leading-base text-txt-secondary">
          {{
            organizationHasMoreThanOneShop && (showOrganizationAverage || allShops.length > 1)
              ? 'Select shop'
              : 'My shop'
          }}
        </div>
        <div class="text-xl leading-2xl">{{ selectedShop }}</div>
      </div>

      <div
        *ngIf="organizationHasMoreThanOneShop && (showOrganizationAverage || allShops.length > 1)"
        class="flex items-center justify-center p-2 bg-primary rounded-2 text-contrast"
      >
        <svg-icon key="garage" fontSize="15px"></svg-icon>
      </div>
    </div>
    <div
      [matMenuTriggerFor]="window.innerWidth >= mobileMaxWidth ? shopSelectorMenu : null"
      #menuTrigger="matMenuTrigger"
    ></div>
  </div>

  <mat-menu
    #shopSelectorMenu="matMenu"
    [hasBackdrop]="true"
    backdropClass="mg-menu-backdrop"
    class="mg-filter-menu"
  >
    <div class="w-[610px] p-3.5" (click)="$event.stopPropagation()">
      <mg-shop-selector
        #shopSelectorRef
        pillSize="s"
        [showOrganizationAverage]="showOrganizationAverage"
        [selectedShopId]="form.get('shopId')?.value"
        (shopIdChanged)="form.get('shopId')?.setValue($event); getShopInfo(); menuTrigger.closeMenu()"
      ></mg-shop-selector>
    </div>
  </mat-menu>

  <!-- Compare With -->
  <div
    class="w-full md:ml-4 flex mt-2 md:mt-0"
    [ngClass]="{ 'md:w-[300px]': !autoSize }"
    *ngIf="filterFields.compareWith"
  >
    <mg-compare-filter
      [currentShopId]="form.get('shopId')?.value"
      [hiddenSections]="hiddenCompareWithSections"
      [compareWith]="this.form.get('compareWith')?.value"
      (selectionsChanged)="setCompareWith($event)"
    ></mg-compare-filter>
  </div>

  <!-- Market Type Selector -->
  <mg-input
    *ngIf="filterFields.marketType"
    controlName="marketType"
    class="form-field w-full md:w-auto mt-1 md:mt-0 print:mt-0"
    type="select"
    icon="arrow-down-simple"
    iconSize="10px"
    label="Select Coverage"
    size="small"
    iconColor="#112027"
    [items]="marketTypes"
    (valueChanged)="setMarketType()"
    extraSelectPanelClass="mg-select-coverage-dropdown"
  ></mg-input>

  <!-- Week Number Selector -->
  <ng-container *ngIf="filterFields.weekNumber">
    <div class="w-full md:w-[300px] md:ml-3 print:ml-3 mt-3 md:mt-0 print:mt-0">
      <div
        class="p-2 rounded-3 bg-ui-bg md:hover:bg-ui-hover flex justify-between items-center"
        [ngClass]="{ 'cursor-pointer': selectedWeekLabel, 'cursor-wait': !selectedWeekLabel }"
        (click)="
          selectedWeekLabel
            ? window.innerWidth < mobileMaxWidth
              ? openWeekSelectorDialog()
              : weekNumberMenuTrigger.openMenu()
            : undefined
        "
      >
        <div class="flex flex-col">
          <div class="text-base leading-base text-txt-secondary">Select week</div>
          <div class="text-xl leading-2xl">{{ selectedWeekLabel }}</div>
        </div>

        <div class="flex items-center justify-center p-2 bg-primary rounded-2 text-contrast">
          <svg-icon key="calendar-light" fontSize="15px"></svg-icon>
        </div>
      </div>
      <div [matMenuTriggerFor]="weekNumberSelectorMenu" #weekNumberMenuTrigger="matMenuTrigger"></div>
    </div>

    <mat-menu #weekNumberSelectorMenu="matMenu" backdropClass="mg-menu-backdrop" class="mg-filter-menu">
      <div class="w-[300px] p-3.5" (click)="$event.stopPropagation()">
        <mg-week-selector
          [availableWeeks]="availableWeeks"
          [selectedWeekNumber]="form.get('weekNumber')?.value"
          (selectedWeekChanged)="setWeekNumber($event); weekNumberMenuTrigger.closeMenu()"
        ></mg-week-selector>
      </div>
    </mat-menu>
  </ng-container>
</form>
