import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs/operators'
import { OrganizationsService } from '../../organizations.service'
import {
  DeleteOrganizationMember,
  GetAllOrganizations,
  GetOneOrganization,
  UpdateOrganization
} from './organizations.actions'
import { OrganizationsStateModel } from './organizations.model'

@State<OrganizationsStateModel>({
  name: 'organizations'
})
@Injectable()
export class OrganizationsState {
  constructor(private organizationsService: OrganizationsService) {}

  @Selector()
  static organization(state: OrganizationsStateModel) {
    return state.organization
  }

  @Selector()
  static allOrganizationsResponse(state: OrganizationsStateModel) {
    return state.allOrganizationsResponse
  }

  @Action(GetAllOrganizations)
  getAllOrganizations(ctx: StateContext<OrganizationsStateModel>, { info }: GetAllOrganizations) {
    return this.organizationsService.getAllOrganizations(info).pipe(
      tap((res) => {
        ctx.patchState({
          allOrganizationsResponse: res
        })
      })
    )
  }

  @Action(GetOneOrganization)
  getOneOrganization(
    ctx: StateContext<OrganizationsStateModel>,
    { organizationId }: GetOneOrganization
  ) {
    return this.organizationsService.getOneOrganization(organizationId).pipe(
      tap((res) => {
        ctx.patchState({ organization: res })
      })
    )
  }

  @Action(UpdateOrganization)
  updateOrganization(
    ctx: StateContext<OrganizationsStateModel>,
    { organizationId, payload }: UpdateOrganization
  ) {
    return this.organizationsService.updateOrganization(organizationId, payload)
  }

  @Action(DeleteOrganizationMember)
  deleteOrganizationMember(
    ctx: StateContext<OrganizationsStateModel>,
    { organizationId, userId }: DeleteOrganizationMember
  ) {
    return this.organizationsService.deleteOrganizationMember(organizationId, userId)
  }
}
