<div class="bg-contrast h-full w-full relative p-3.5 pt-[26px] overflow-auto">
  <!-- Close Btn -->
  <div class="absolute cursor-pointer top-4.5 right-4.5">
    <svg-icon color="#6B7980" key="close" fontSize="17px" (click)="dialogRef.close()"></svg-icon>
  </div>

  <mg-shop-selector
    pillSize="m"
    [showOrganizationAverage]="data.showOrganizationAverage"
    [selectedShopId]="data.selectedShopId"
    (shopIdChanged)="dialogRef.close($event)"
  ></mg-shop-selector>
</div>
