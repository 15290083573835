<div class="p-3.5 md:p-0 flex-1 flex flex-col md:justify-center bg-contrast">
  <mg-add-shop-sections [activeSection]="3"></mg-add-shop-sections>

  <div class="title pt-3.5">Select shop focus</div>
  <div class="sub-title pb-4">Select the product mix that best describes your shop.</div>

  <form [formGroup]="form" (ngSubmit)="onSubmit()" class="flex-1 md:flex-none flex flex-col">
    <div class="flex-1 md:flex-none flex flex-col">
      <div class="mt-3 first:mt-0" *ngFor="let item of shopFocusItems">
        <mg-radio [item]="item" controlName="focusType"></mg-radio>
      </div>
    </div>

    <mg-button
      class="mt-5.5 w-full"
      type="submit"
      size="medium"
      icon="arrow-right-full"
      iconPosition="right"
      iconType="svgIcon"
      iconClass="flex"
      [loading]="isProcessing"
      [disabled]="form.invalid"
    >
      Continue
    </mg-button>
    <mg-button
      class="mt-2 md:pb-2 w-full"
      type="button"
      size="medium"
      variant="ghost"
      (click)="completed.emit()"
    >
      Skip
    </mg-button>
  </form>
</div>
