<mat-form-field
  [appearance]="appearance"
  class="w-full mg-input"
  [ngClass]="{
    'mg-input-extra-small': size === 'extra-small',
    'mg-input-smaller': size === 'smaller',
    'mg-input-small': size === 'small',
    'mg-input-medium': size === 'medium',
    'mg-input-no-padding': noPadding,
    '!text-right': rightAlign,
    'input-default': variant === 'default',
    'input-secondary': variant === 'secondary',
    'input-white': variant === 'white',
    'input-ui-page': variant === 'ui-page'
  }"
>
  <mat-label
    *ngIf="label && size !== 'extra-small'"
    class="tracking-normal text-xl"
    [ngClass]="{
      'text-error': isFormControlInvalid(),
      'text-txt':
        (variant === 'default' || variant === 'white' || variant === 'ui-page') &&
        !isFormControlInvalid(),
      'text-txt-secondary': variant === 'secondary' && !isFormControlInvalid()
    }"
  >
    <span class="inline-block lowercase first-letter:uppercase">{{ label }}</span>
  </mat-label>
  <span *ngIf="prefix" class="input-has-prefix" matPrefix>{{ prefix }} &nbsp;</span>

  <ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="'select'">
      <ng-container *ngTemplateOutlet="selectInputTemplate"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'date'">
      <ng-container *ngTemplateOutlet="datePickerTemplate"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'google-places'">
      <ng-container *ngTemplateOutlet="googlePlacesInputTemplate"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'textarea'">
      <ng-container *ngTemplateOutlet="textAreaTemplate"></ng-container>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <ng-container *ngTemplateOutlet="normalInputTemplate"></ng-container>
    </ng-container>
  </ng-container>

  <mat-progress-spinner *ngIf="loading" matSuffix [diameter]="16" mode="indeterminate">
  </mat-progress-spinner>
  <ng-container matSuffix *ngIf="!loading && icon && type !== 'password'">
    @if (clearable && formControl.value && formControl.value !== '') {
      <svg-icon
        key="close"
        [color]="clearIconColor"
        [fontSize]="clearIconSize"
        (click)="formControl.reset()"
      ></svg-icon>
    } @else {
      <svg-icon
        [key]="icon"
        [color]="iconColor"
        [fontSize]="iconSize"
        (click)="iconClicked.emit($event)"
      ></svg-icon>
    }
  </ng-container>
  <svg-icon
    matSuffix
    *ngIf="!loading && type === 'password'"
    key="eye-light"
    [color]="iconColor"
    [fontSize]="iconSize"
    (click)="showPassword = !showPassword"
  ></svg-icon>

  <mat-error
    class="text-base text-error tracking-normal mt-0.5"
    *ngIf="isFormControlInvalid() && handleErrorMessage() !== ''"
  >
    {{ handleErrorMessage() }}
  </mat-error>

  <ng-template #normalInputTemplate ngProjectAs="[matInput]">
    <input
      *ngIf="!useCurrencyMask"
      [id]="normalInputId"
      class="{{ extraInputClass }}"
      [mask]="mask"
      [suffix]="suffix"
      matInput
      [type]="showPassword ? 'text' : type"
      [formControl]="formControl"
      [mgAutofocus]="autoFocus"
      [errorStateMatcher]="customErrorMatcher ? customErrorMatcher : errorMatcher"
      autocomplete="on"
      [placeholder]="placeholder"
      [ngClass]="{ 'text-center': normalInputCenter }"
      [attr.inputmode]="inputmode"
      (focus)="focused.emit()"
      (blur)="blured.emit()"
    />
    <input
      *ngIf="useCurrencyMask"
      class="{{ extraInputClass }}"
      currencyMask
      [options]="{ align: 'left', prefix: '$' }"
      matInput
      type="text"
      [formControl]="formControl"
      [mgAutofocus]="autoFocus"
      [errorStateMatcher]="customErrorMatcher ? customErrorMatcher : errorMatcher"
      autocomplete="on"
      [placeholder]="placeholder"
      [ngClass]="{ 'text-center': normalInputCenter }"
      (focus)="focused.emit()"
      (blur)="blured.emit()"
    />
  </ng-template>

  <ng-template #textAreaTemplate ngProjectAs="[matInput]">
    <textarea
      class="{{ extraInputClass }}"
      [mask]="mask"
      matInput
      [formControl]="formControl"
      [mgAutofocus]="autoFocus"
      [errorStateMatcher]="customErrorMatcher ? customErrorMatcher : errorMatcher"
      autocomplete="on"
      cdkTextareaAutosize
      [cdkAutosizeMinRows]="minTextAreaRows"
      [cdkAutosizeMaxRows]="maxTextAreaRows"
      [placeholder]="placeholder"
      (focus)="focused.emit()"
      (blur)="blured.emit()"
    ></textarea>
  </ng-template>

  <ng-template #googlePlacesInputTemplate ngProjectAs="[matInput]">
    <input
      matInput
      class="{{ extraInputClass }}"
      type="text"
      [formControl]="formControl"
      [mgAutofocus]="autoFocus"
      [errorStateMatcher]="customErrorMatcher ? customErrorMatcher : errorMatcher"
      id="google-places-input"
      [placeholder]="placeholder"
      (focus)="focused.emit()"
      (blur)="blured.emit()"
    />
  </ng-template>

  <ng-template #datePickerTemplate ngProjectAs="[matDatepicker]">
    <input
      matInput
      class="{{ extraInputClass }}"
      [matDatepicker]="picker"
      [formControl]="formControl"
      [max]="maxDate"
      (focus)="picker.open()"
      (click)="picker.open()"
      [placeholder]="placeholder"
      (focus)="focused.emit()"
      (blur)="blured.emit()"
    />
    <mat-datepicker #picker></mat-datepicker>
  </ng-template>

  <ng-template #selectInputTemplate ngProjectAs="mat-select">
    <mat-select
      [formControl]="formControl"
      disableOptionCentering
      panelClass="mg-select-panel {{ extraSelectPanelClass }}"
    >
      <mat-option *ngFor="let item of items" [value]="item.value" [disabled]="item.disabled">
        {{ item.label }}
      </mat-option>
    </mat-select>
  </ng-template>
</mat-form-field>
