export type ICompareWithType = 'shops' | 'states' | 'regions' | 'nationals'

export interface ICompareWithSelection {
  organizationAverage: boolean
  shops: string[]
  states: string[]
  regions: string[]
  nationals: string[]
}

export const CompareWithInitialValue: ICompareWithSelection = {
  organizationAverage: false,
  shops: [],
  states: [],
  regions: [],
  nationals: []
}
