import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'
import { ISelectItem } from '@mg-platform/core/core-ui'
import {
  SetShopFocus,
  ShopFocusType,
  ShopFocusTypeToLabel,
  ShopsState
} from '@mg-platform/shops/shops-data-access'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Store } from '@ngxs/store'
import { finalize } from 'rxjs'

@UntilDestroy()
@Component({
  selector: 'mg-add-shop-focus',
  templateUrl: './add-shop-focus.component.html',
  styleUrls: ['./add-shop-focus.component.scss']
})
export class AddShopFocusComponent implements OnInit {
  @Output() completed = new EventEmitter()

  shopId: string
  form: UntypedFormGroup
  isProcessing = false
  shopFocusItems: ISelectItem[] = Object.values(ShopFocusType)
    .filter((el) => el !== ShopFocusType.NotSet)
    .map((el) => ({ label: ShopFocusTypeToLabel[el], value: el }))

  constructor(private fb: UntypedFormBuilder, private store: Store) {}

  ngOnInit() {
    this.shopId = this.store.selectSnapshot(ShopsState.shop)?.id
    this.form = this.fb.group({
      focusType: [ShopFocusType.NotSet]
    })
  }

  onSubmit() {
    this.form.markAllAsTouched()
    if (this.form.invalid || this.isProcessing) {
      return
    }

    this.isProcessing = true
    this.store
      .dispatch(new SetShopFocus(this.form.value, this.shopId))
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          this.isProcessing = false
        })
      )
      .subscribe(() => {
        this.completed.emit()
      })
  }
}
