<div
  class="w-full bg-ui-page p-3.5 mx-auto header-container lg:hidden flex items-center justify-between"
  style="z-index: 1000"
>
  <img
    class="cursor-pointer"
    src="assets/images/icons/logo.svg"
    routerLink="/dashboard"
    width="31"
    alt="Logo"
  />

  <div class="flex items-center">
    <div class="flex cursor-pointer mr-5 text-txt" (click)="openMenuChangeModeDialog()">
      <svg-icon key="palette" fontSize="24px"></svg-icon>
    </div>

    <div
      class="flex cursor-pointer mr-5 text-txt"
      (click)="openMenuItemsDialog(profileMenuRoutes, true)"
    >
      <svg-icon key="user" fontSize="24px"></svg-icon>
    </div>

    <div
      class="flex pt-[2px] cursor-pointer text-txt"
      (click)="openMenuItemsDialog(mainMenuRoutes, false)"
    >
      <svg-icon key="menu" fontSize="24px"></svg-icon>
    </div>
  </div>
</div>
