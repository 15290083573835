import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { CoreUiModule } from '@mg-platform/core/core-ui'
import { FilterFormComponent } from './components/filter-form/filter-form.component'
import { MainHeaderComponent } from './components/main-header/main-header.component'
import { RouterModule } from '@angular/router'
import { SvgIconsModule } from '@ngneat/svg-icon'
import { MatMenuModule } from '@angular/material/menu'
import { MatIconModule } from '@angular/material/icon'
import { ProfileLayoutComponent } from './components/profile-layout/profile-layout.component'
import { PageFilterHeaderComponent } from './components/page-filter-header/page-filter-header.component'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { SideMenuComponent } from './components/side-menu/side-menu.component'
import { PageTitleComponent } from './components/page-title/page-title.component'
import { MenuHeaderItemsDialogComponent } from './components/menu-header-items-dialog/menu-header-items-dialog.component'
import { ShopSelectorDialogComponent } from './components/shop-selector-dialog/shop-selector-dialog.component'
import { ShopSelectorComponent } from './components/shop-selector/shop-selector.component'
import { RingChartComponent } from './components/ring-chart/ring-chart.component'
import { ElementSliderComponent } from './components/element-slider/element-slider.component'
import { SwiperModule } from 'swiper/angular'
import { ResponsiveSwitcherComponent } from './components/responsive-switcher/responsive-switcher.component'
import { CompareFilterComponent } from './components/compare-filter/compare-filter.component'
import { CompareWithDialogComponent } from './components/compare-with-dialog/compare-with-dialog.component'
import { CompareWithComponent } from './components/compare-with/compare-with.component'
import { RangeDatePickerFilterComponent } from './components/range-date-picker-filter/range-date-picker-filter.component'
import { RangeDatePickerDialogComponent } from './components/range-date-picker-dialog/range-date-picker-dialog.component'
import { RangeDatePickerComponent } from './components/range-date-picker/range-date-picker.component'
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter'
import {
  DefaultMatCalendarRangeStrategy,
  MAT_DATE_RANGE_SELECTION_STRATEGY,
  MatDatepickerModule
} from '@angular/material/datepicker'
import { CustomToastComponent } from './components/custom-toast/custom-toast.component'
import { IncludedShopsButtonComponent } from './components/included-shops-button/included-shops-button.component'
import { IncludedShopsComponent } from './components/included-shops/included-shops.component'
import { IncludedShopsDialogComponent } from './components/included-shops-dialog/included-shops-dialog.component'
import { RingChartAnimatableComponent } from './components/ring-chart-animatable/ring-chart-animatable.component'
import { DirectivesModule } from '@mg-platform/core/core-util'
import { WeekSelectorComponent } from './components/week-selector/week-selector.component'
import { WeekSelectorDialogComponent } from './components/week-selector-dialog/week-selector-dialog.component'
import { MenuChangeModeDialogComponent } from './components/menu-change-mode-dialog/menu-change-mode-dialog.component'
import { UpdateThemeCardComponent } from './components/update-theme-card/update-theme-card.component'
import { DynamicImagesComponent } from './components/dynamic-images/dynamic-images.component'
import { NoReportBannerComponent } from './components/no-report-banner/no-report-banner.component'
import { ShopFocusDialogComponent } from './components/shop-focus-dialog/shop-focus-dialog.component'
import { DynamicIconsComponent } from './components/dynamic-icons/dynamic-icons.component'
import { TableActionsComponent } from './widgets/table-actions/table-actions.component'
import { AddShopSectionsComponent } from './components/add-shop-sections/add-shop-sections.component'
import { AddShopFormComponent } from './components/add-shop/add-shop.component'
import { AddShopServicePriceComponent } from './components/add-shop-service-price/add-shop-service-price.component'
import { AddShopFocusComponent } from './components/add-shop-shop-focus/add-shop-focus.component'
import { AddShopProcessComponent } from './components/add-shop-process/add-shop-process.component'
import { TableActionSortComponent } from './widgets/table-actions/components/table-action-sort/table-action-sort.component'
import { DropdownMenuComponent } from './components/dropdown-menu/dropdown-menu.component'
import { CountryFlagComponent } from './components/country-flag/country-flag.component'
import { TableFilterComponent } from './widgets/table-actions/components/table-filter/table-filter.component'
import { TableHeaderComponent } from './components/table-header/table-header.component'

@NgModule({
  declarations: [
    FilterFormComponent,
    MainHeaderComponent,
    ProfileLayoutComponent,
    PageFilterHeaderComponent,
    SideMenuComponent,
    PageTitleComponent,
    MenuHeaderItemsDialogComponent,
    ShopSelectorDialogComponent,
    ShopSelectorComponent,
    RingChartComponent,
    ElementSliderComponent,
    ResponsiveSwitcherComponent,
    CompareFilterComponent,
    CompareWithComponent,
    CompareWithDialogComponent,
    RangeDatePickerFilterComponent,
    RangeDatePickerDialogComponent,
    RangeDatePickerComponent,
    CustomToastComponent,
    IncludedShopsButtonComponent,
    IncludedShopsComponent,
    IncludedShopsDialogComponent,
    RingChartAnimatableComponent,
    WeekSelectorComponent,
    WeekSelectorDialogComponent,
    MenuChangeModeDialogComponent,
    UpdateThemeCardComponent,
    NoReportBannerComponent,
    DynamicImagesComponent,
    ShopFocusDialogComponent,
    DynamicIconsComponent,
    TableActionsComponent,
    TableActionSortComponent,
    AddShopFormComponent,
    AddShopSectionsComponent,
    AddShopFocusComponent,
    AddShopServicePriceComponent,
    AddShopProcessComponent,
    DropdownMenuComponent,
    CountryFlagComponent,
    TableFilterComponent,
    TableHeaderComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CoreUiModule,
    RouterModule,
    SvgIconsModule,
    MatMenuModule,
    MatIconModule,
    MatProgressSpinnerModule,
    SwiperModule,
    MatDatepickerModule,
    MatMomentDateModule,
    DirectivesModule
  ],
  exports: [
    FilterFormComponent,
    MainHeaderComponent,
    ProfileLayoutComponent,
    PageFilterHeaderComponent,
    SideMenuComponent,
    PageTitleComponent,
    RingChartComponent,
    ElementSliderComponent,
    ResponsiveSwitcherComponent,
    RangeDatePickerFilterComponent,
    CompareFilterComponent,
    CustomToastComponent,
    IncludedShopsButtonComponent,
    RingChartAnimatableComponent,
    UpdateThemeCardComponent,
    NoReportBannerComponent,
    DynamicImagesComponent,
    DynamicIconsComponent,
    TableActionsComponent,
    AddShopProcessComponent,
    DropdownMenuComponent,
    AddShopProcessComponent,
    CountryFlagComponent,
    TableHeaderComponent
  ],
  providers: [
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: DefaultMatCalendarRangeStrategy
    }
  ]
})
export class CoreFeatureModule {}
