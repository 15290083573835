<div
  class="border-b border-gray-200 flex items-center md:justify-start overflow-x-auto hide-scrollbar"
  [ngClass]="{
    'justify-between': smallScreensSpaceBetween
  }"
>
  <a
    *ngFor="let item of sections"
    class="tab-item relative group"
    [ngStyle]="{ minWidth: item.minWidth ? item.minWidth + 'px' : 'auto' }"
    [ngClass]="{
      'tab-item-active': selectedSection === item.key,
      'md:mr-5': smallScreensSpaceBetween,
      'mr-5': !smallScreensSpaceBetween
    }"
    (click)="selectedSectionChanged.emit(item.key)"
  >
    <div
      class="hidden group-hover:!block absolute h-0.5 bg-primary w-full bottom-0"
      [ngClass]="{ '!block': selectedSection === item.key }"
    ></div>
    <div
      class="tab-icon"
      [ngClass]="{
        '!hidden md:!block': item.showIconMedia === 'desktop',
        'md:!hidden': item.showIconMedia === 'mobile'
      }"
      *ngIf="item.icon"
    >
      <svg-icon [key]="item.icon" fontSize="15px"></svg-icon>
    </div>
    <span [ngClass]="{ 'hidden md:block': item.smallScreensTitle }">{{ item.title }}</span>
    <span class="md:hidden" *ngIf="item.smallScreensTitle">{{ item.smallScreensTitle }}</span>
  </a>
</div>
